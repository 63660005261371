<template>
  <span>
    <input v-if="type === 'freeText'" v-model="localValue" type="text">
    <multiselect v-else-if="type === 'choice'" v-model="localValue" :options="options.choices" />
  </span>
</template>

<script>
import { ref, watch } from 'vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import useDebounce from '@/hooks/debounce';

export default {
  name: 'EntityFilter',
  components: { Multiselect },
  props: {
    type: {
      type: String,
      default: 'searchFilter',
    },
    modelValue: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const localValue = ref(props.modelValue);

    useDebounce(localValue, 500, () => {
      emit('update:model-value', localValue.value);
    });

    watch(() => props.modelValue, (val) => {
      localValue.value = val;
    });

    return {
      localValue,
    };
  },
};
</script>

<style scoped>

</style>
