import { onUnmounted, ref, watch } from 'vue';

export default function useDebounce(watchee, timeout, cb) {
  const debouncer = ref(null);

  const debounce = () => {
    if (debouncer.value) {
      window.clearTimeout(debouncer.value);
    }

    debouncer.value = window.setTimeout(cb, timeout);
  };

  onUnmounted(() => {
    window.clearTimeout(debouncer.value);
  });

  watch(watchee, () => debounce());

  return {
    debouncer,
  };
}
