<template>
  <div>
    <label v-if="formLabel" for="id" :class="{ 'partly-required': partlyRequired }" class="mt-4">
      {{ formLabel }}
      {{ required ? '*' : '' }}
      {{ partlyRequired ? '(*)' : '' }}
    </label>
    <vueform-multiselect
      id="id"
      v-model="value"
      :mode="mode"
      :options="options"
      :loading="loading"
      :delay="250"
      :min-chars="1"
      :resolve-on-load="true"
      :searchable="searchable"
      :value-prop="valueProp"
      :track-by="trackBy"
      :label="label"
      :filter-results="filterResults"
      :clear-on-select="mode === 'single'"
      :close-on-select="mode === 'single'"
      :placeholder="placeholder"
      :required="required"
      :no-options-text="t('general.form.typeToSearch')"
      :no-results-text="t('general.form.noResults')"
    >
      <template #option="{ option, search }">
        <slot name="option" v-bind="{ option, search }" />
      </template>
      <template #singlelabel="{ value: val }">
        <slot name="singlelabel" v-bind="{ value: val }" />
      </template>
      <template #tag="{ option, handleTagRemove, disabled }">
        <slot name="tag" v-bind="{ option, handleTagRemove, disabled }" />
      </template>
    </vueform-multiselect>
    <small v-if="error" class="text-red" v-text="te(error) ? t(error, [formLabel]) : error" />
  </div>
</template>

<script>
import slugify from 'slugify';
import Multiselect from '@vueform/multiselect';
import { useI18n } from 'vue-i18n';
import { useModel } from '@/hooks/model';

/**
 * For info about props of Multiselect, see
 * https://github.com/vueform/multiselect
 */
export default {
  name: 'Multiselect',
  components: { VueformMultiselect: Multiselect },
  props: {
    formLabel: { type: String, default: '' },
    modelValue: { type: [String, Array], default: null },
    options: { type: [Function, Array], default: () => [] },
    mode: { type: String, default: 'single' },
    loading: { type: Boolean, default: false },
    valueProp: { type: String, default: '@id' },
    trackBy: { type: String, default: 'name' },
    label: { type: String, default: 'name' },
    searchable: { type: Boolean, default: true },
    placeholder: { type: String, default: null },
    filterResults: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    partlyRequired: { type: Boolean, default: false },
    error: { type: String, default: null },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const value = useModel(props, emit);
    const id = slugify(props.formLabel);

    return {
      t,
      te,
      value,
      id,
    };
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
