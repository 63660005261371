<template>
  <div class="action">
    <component
      :is="options[action] ? 'router-link' : 'a'"
      v-for="action in actions"
      :key="'action-' + action"
      :to="options[action] ?? '#'"
      @click="(e) => {
        if (!options[action]) { e.preventDefault(); return $emit('click', action); }
      }"
    >
      <font-awesome-icon v-if="action === 'edit'" icon="pen-to-square" class="icon" />
      <font-awesome-icon v-else-if="action === 'delete'" icon="trash-can" class="icon" />
      <font-awesome-icon v-else-if="action === 'detail'" icon="eye" class="icon" />
      <font-awesome-icon v-else-if="action === 'qr'" icon="qrcode" class="icon" />
      <font-awesome-icon v-else-if="action === 'list'" icon="list" class="icon" />
      <span v-else v-text="te(action) ? t(action) : action" />
    </component>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: 'EntityActions',
  components: {
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'click',
  ],
  setup() {
    const { t, te } = useI18n();
    return {
      t,
      te,
    };
  },
};
</script>

<style lang="scss" scoped>
.action {
  @apply h-full flex items-center justify-end;

  & > *:not(:last-child) {
    @apply mr-6;
  }
}

.icon {
  @apply inline-block w-5;
}
</style>
