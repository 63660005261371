<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
    version="1.1"
    x="0px"
    y="0px"
    class="w-6 h-6"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g class="sort-icon-arrow-up" :fill="direction == null || direction.toLowerCase() === 'asc' ? 'currentColor' : '#aaa'">
        <path d="M33,45 L50,17 L67,45 L33,45 Z" />
      </g>
      <g class="sort-icon-arrow-down" :fill="direction == null || direction.toLowerCase() === 'desc' ? 'currentColor' : '#aaa'">
        <path d="M67,55 L50,83 L33,55 L67,55 Z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Sort',
  props: {
    direction: {
      type: String,
      default: '',
    },
  },
};
</script>
