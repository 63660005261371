<template>
  <div>
    <div class="-mx-4 overflow-x-auto">
      <table class="entity-table">
        <thead>
          <tr>
            <th
              v-for="field in visibleFields"
              :key="field.key"
              scope="col"
              class="text-left"
              :class="{ 'cursor-pointer': field.sortable }"
            >
              <div class="flex items-center" :class="{ 'justify-end' : field.key === '_actions' }">
                <span @click="field.sortable ? changeSort(field.key) : undefined">
                  {{ field.key === '_actions' ? t('general.action.label', 2) : field.label }}
                </span>
                <Sort
                  v-if="field.sortable"
                  :direction="field.key === sortBy ? sortDirection : null"
                  @click="field.sortable ? changeSort(field.key) : undefined"
                />
              </div>
              <EntityFilter
                v-if="field.searchFilter"
                :type="field.searchFilter.type"
                :model-value="filters[field.key]"
                :options="field.searchFilter.options"
                @update:model-value="changeFilter(field.key, $event)"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td :colspan="fields.length" class="text-center">
              <spinner color="#000000" />
            </td>
          </tr>
          <template v-else>
            <tr
              v-for="entity in entities"
              :key="entity.id"
              :class="{ 'cursor-pointer': rowClickable }"
              @click="$emit('click:row', entity)"
            >
              <td
                v-for="(field) in visibleFields"
                :key="entity.id + '_' + field.key"
                class=""
              >
                <slot v-bind="{ value: get(entity, field.key), entity }" :name="'cell(' + field.key + ')'">
                  <EntityActions
                    v-if="field.key === '_actions'"
                    :actions="field.actions"
                    :options="field.actionOptions ? field.actionOptions(entity) : {}"
                    @click="emitAction($event, entity)"
                  />
                  <template v-else-if="typeof get(entity, field.key) === 'boolean'">
                    <checkmark-icon v-if="get(entity, field.key)" class="block w-6 h-6" />
                    <close-icon v-else class="block w-6 h-6" />
                  </template>
                  <template v-else>
                    <slot v-bind="{ entity }" :name="field.key">
                      {{ printValue(entity, field) }}
                    </slot>
                  </template>
                </slot>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div class="pagination" v-if="numberOfPages > 0">
      Seite
      <template v-for="(n, idx) in pages" :key="'page-' + n">
        <span v-if="idx !== 0 && n !== pages[idx - 1] + 1" class="spacer">
          ...
        </span>
        <a
          :class="{ current: currentPage === n }"
          href="#"
          @click.prevent="$emit('update:page', n)"
          v-text="n"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { get } from 'lodash/object';
import { range } from 'lodash';
import Sort from '@/components/utils/Sort.vue';
import Spinner from '@/components/utils/spinner.vue';
import EntityFilter from '@/components/shared/EntityTable/EntityFilter.vue';
import EntityActions from '@/components/shared/EntityTable/EntityActions.vue';
import CloseIcon from '@/assets/images/close.svg';
import CheckmarkIcon from '@/assets/images/checkmark.svg';

export default {
  name: 'EntityTable',
  components: {
    EntityFilter,
    EntityActions,
    Spinner,
    Sort,
    CloseIcon,
    CheckmarkIcon,
  },
  props: {
    /**
     * Show Spinner
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Define columns
     * @var {
     *   key: string,
     *   sortable: boolean,
     *   label: string,
     *   displayFilter: (v) => string,
     *   searchFilter: { type: string, default: string }
     *   visible: boolean,
     * }
     */
    fields: {
      type: Array,
      required: true,
    },
    /**
     * Entities to show
     */
    entities: {
      type: Array,
      required: true,
    },
    /**
     * Show pointer cursor on rows
     */
    rowClickable: {
      type: Boolean,
      default: false,
    },
    /**
     * Property by which entities are sorted
     */
    sortBy: {
      type: [String, Array],
      default: null,
    },
    /**
     * Current sort direction
     */
    sortDirection: {
      type: [String, Array],
      default: null,
    },
    /**
     * For pagination
     */
    totalItems: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    /**
     * What filters to show
     */
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'click:row',
    'click:edit',
    'click:delete',
    'click:batchCreate',
    'update:page',
    'update:filters',
    'update:sortBy',
    'update:sortDirection',
    'update:sort',
  ],
  setup() {
    const { t } = useI18n();
    return { t };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((f) => f.visible === undefined || f.visible === true);
    },
    numberOfPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    pages() {
      const startPages = 1;
      const endPages = 1;
      const padding = 1;
      const pages = [];
      pages.push(...range(1, startPages + 1));
      pages.push(...range(this.currentPage - padding, this.currentPage + padding + 1));
      pages.push(...range(this.numberOfPages + 1 - endPages, this.numberOfPages + 1));
      return new Int32Array([...new Set(pages.filter((p) => p > 0 && p <= this.numberOfPages))]).sort();
    },
  },
  methods: {
    emitAction(action, entity) {
      this.$emit(`click:${action}`, entity);
    },
    printValue(entity, fieldDefinition) {
      const value = get(entity, fieldDefinition.key);
      if (Array.isArray(value)) {
        return value.map(fieldDefinition.filter ? fieldDefinition.filter : (v) => v).join(', ');
      }

      return fieldDefinition.displayFilter ? fieldDefinition.displayFilter(value, entity) : value;
    },
    changeSort(sortBy) {
      this.$emit('update:sortBy', sortBy);
      if (this.sortBy !== sortBy || (this.sortBy === sortBy && this.sortDirection === 'desc')) {
        this.$emit('update:sortDirection', 'asc');
      } else {
        this.$emit('update:sortDirection', 'desc');
      }
      this.$emit('update:sort');
    },

    get(object, path) {
      return get(object, path);
    },

    changeFilter(key, value) {
      this.$emit('update:filters', { [key]: value });
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  @apply mt-4 flex justify-end items-center;

  a {
    @apply text-sm w-6 h-6 ml-2 flex items-center justify-center;
    @apply border border-primary rounded-full;

    &.current {
      @apply text-white bg-primary;
    }
  }
  .spacer {
    @apply text-sm w-6 h-6 ml-2 flex items-center justify-center;
  }
}
</style>
